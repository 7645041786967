<template>
    <CorretoraList v-if="!isCadastrarOuEditar()" />
    <CorretoraForm
        v-if="isCadastrarOuEditar()"
        :codigoCliente="codigoCliente"
    />
</template>

<script>
import CorretoraForm from '../../components/cadastro/CorretoraForm.vue';
import CorretoraList from '../../components/cadastro/CorretoraList.vue';

export default {
    name: 'PageCorretora',
    components: { CorretoraForm, CorretoraList },
    
    data() {
        return {
            isCadastro: false,
            isEditar: false,
            codigoCliente: null
        }
    },
    
    created() {
        this.validarRouter();
    },

    updated() {
        this.validarRouter();
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }                
    },

    methods: {         

        editar(codigoCliente) {
            if(codigoCliente) {
                this.codigoCliente = codigoCliente;
                this.isEditar = true;
            }
            else {
                this.codigoCliente = null;
                this.isEditar = false;
            }
        },

        isCadastrarOuEditar() {
            return this.isCadastro || this.isEditar;
        },

        validarRouter() {
            const routerName = this.$route.name
            if(routerName === 'cadastrarCorretora') {
                this.isCadastro = true;
            }
            else if(routerName === 'editarCorretora') {
                const codigoCliente = this.$route.params.codigoCliente;
                this.editar(codigoCliente);
            }
            else {
                this.isCadastro = false;
                this.isEditar = false;
                this.codigoCliente = null;
            }
        }
    }
}
</script>