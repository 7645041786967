<template>
    <div class="card">
        <div class="flex align-items-center">
            <i class="pi pi-building" style="font-size: 1.4rem"></i> 
           <h3 style="margin:0px 5px;">Corretoras</h3>
        </div>

        <hr />

        <DataTable 
            responsiveLayout="stack"
            :value="data"
            v-model:selection="corretoraSelecionada"
            selectionMode="single"
            showGridlines
            @sort="sort($event)">

            <template #header>
                <div class="flex justify-content-between">
                    <div class="flex">
                        <Button 
                            label="Cadastrar"
                            icon="pi pi-plus"
                            class="p-button-info mr-2"
                            @click="cadastrar()"
                        ></Button>

                        <Button
                            label="Limpar filtro"
                            icon="pi pi-filter-slash"
                            class="p-button-outlined mr-2"
                            @click="limparFiltro()"
                        ></Button>

                        <Button
                            v-if="filter.situacao == 'ATIVO'"
                            type="button"
                            icon="pi pi-ban"
                            label="Listar inativos"
                            class="p-button-outlined p-button-danger"
                            @click="filter.situacao = 'INATIVO'; carregarDados()"
                        ></Button>

                        <Button
                            v-if="filter.situacao == 'INATIVO'"
                            type="button"
                            icon="pi pi-check-circle"
                            label="Listar ativos"
                            class="p-button-outlined"
                            @click="filter.situacao = 'ATIVO'; carregarDados()"
                        ></Button>
                    </div>

                    <span class="p-input-icon-right">
                        <i class="pi pi-search"></i>
                        <InputText 
                            v-model="filter.perquisar"
                            placeholder="Pesquisar"
                            @keyup.enter="carregarDados()"
                        />
                    </span>
                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="id" header="Código" />
            <Column field="codigoCliente" header="Nro. Corretora" />
            <Column field="nome" header="Corretora" sortable />
            <Column field="contato" header="Contato" />
            <Column field="telefone" header="Telefone" />
            <Column field="celular" header="Celular" />
            <Column header="Atualização">
                <template #body="{ data }">
                    {{ formatDateCustom(data.dataAtualizacao, 'DD/MM/YYYY') }}
                </template>
            </Column>
            <Column header="Ações" style="width:9rem;">
                <template #body="{ data }">
                    <div class="flex-none">
                        <Button 
                            title="Visualizar histórico"
                            icon="pi pi-list"
                            class="p-button-rounded p-button-warning flex-none mr-2"
                            @click="visualizarHistorico(data)"
                        ></Button>
                        
                        <Button
                            v-if="data.ativo"
                            title="Editar"
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button-info flex-none mr-2"
                            @click="editar(data.codigoCliente)"
                        ></Button>

                        <Button 
                            v-if="data.ativo"
                            title="Inativar"
                            class="p-button-rounded flex-none p-button-danger"
                            icon="pi pi-ban"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>

                        <Button 
                            v-if="!data.ativo"
                            title="Ativar"
                            class="p-button-rounded flex-none p-button-info"
                            icon="pi pi-check-circle"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>
                    </div>
                </template>
            </Column>
        </DataTable>

        <Paginator
            v-model:first="primeiraLinha"
            :rows="qtdRegistroPorPagina"
            :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]"
            @page="onPage" />

    </div>

    <Dialog v-model:visible="ativarOuInativarDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>Deseja <strong>{{ corretoraSelecionada.ativo ? 'inativar' : 'ativar' }}</strong> a corretora <strong>{{ corretoraSelecionada.nome }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false"></Button>
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()"></Button>
        </template>
    </Dialog>

    <ModalHistoricoGenerico
        codigoPagina="CORRETORA"
        :id="corretoraSelecionada.id"
        :descricao="corretoraSelecionada.corretora"
        :visivel="historicoDialog"
        @fechar="ocultarHistorico()"
    />
</template>

<script>
import Formatacao from '../../utilities/Formatacao';
import StorageService from '../../service/StorageService';
import CorretoraService from '../../service/CorretoraService'
import ModalHistoricoGenerico from '../ModalHistoricoGenerico.vue';

export default {
    name: 'CorretoraList',
    components: { ModalHistoricoGenerico },
    data() {
        return {
            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 10,  

            data: [],

            filter: {},
            ordenar: 'nome,asc',
            
            corretoraSelecionada: {},
            ativarOuInativarDialog: false,

            historicoDialog: false,
            
            storange: StorageService.getControlePagina("CORRETORA"),    
            storangeMessage: StorageService.getMensagemToast("CORRETORA")
        }
    },

    created() {
        //verifica se o filtro está vazio
        this.filter.situacao = "ATIVO";

        if(this.storange.default == undefined){
            this.filter = this.storange.filtros;
        }

        this.pagina = this.storange.numero;
        this.primeiraLinha = this.storange.firstRow;
        this.qtdRegistroPorPagina = this.storange.qtdRegistro; 
        this.carregarDados();
        this.exibirMensagemToast();
    },

    methods: {
        exibirMensagemToast() {
            if (this.storangeMessage.active){
                this.$toast.add({
                    severity: this.storangeMessage.severity,
                    summary: this.storangeMessage.summary,
                    detail: this.storangeMessage.detail, 
                    life: 7500
                });
                StorageService.setMensagemToast("CORRETORA", 'info', '', '', false);
            }
        },

        cadastrar() {
            this.$router.push(`/corretora/cadastrar`);
        },

        editar(codigoCliente) {                
            this.$router.push(`/corretora/editar/${ codigoCliente }`);
        },

        carregarDados() {
            const params = {
                page: this.pagina, 
                size: this.qtdRegistroPorPagina, 
                sort: this.ordenar
            };

            if(!!this.filter.perquisar) {
                params["nome"] = this.filter.perquisar;
            }

            if(!!this.filter.situacao) {
                params["situacao"] = this.filter.situacao;
            }

            CorretoraService.buscarComPaginacao(params)
                .then(({ data }) => {
                    if(data) {
                        this.data = data.content;
                        this.qtdRegistroPorPagina = data.pageable.pageSize;
                        this.totalRegistro = data.totalElements;
                    }
                })
                .catch(error => {
                    const toast = {
                        severity:'error',
                        summary: 'Falha ao buscar corretoras',
                        detail:`Falha não mapeada.`, 
                        life: 7500
                    };

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
            StorageService.setControlePaginaFilter("CORRETORA", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);               
        },

        limparFiltro() {
            this.filter = {};
            this.filter.situacao = "ATIVO";
            this.carregarDados();
        },

        confirmarAtivarOuInativar(corretora) {
            this.corretoraSelecionada = corretora;
            this.ativarOuInativarDialog = true;
        },

        ativarOuInativar() {
            if(this.corretoraSelecionada.ativo) {
                CorretoraService.inativar(this.corretoraSelecionada.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Inativado',
                            detail:`A corretora foi inativada!`, 
                            life: 7500
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.corretoraSelecionada = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao inativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
            else {
                CorretoraService.ativar(this.corretoraSelecionada.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Ativada',
                            detail:`A corretora foi ativada!`, 
                            life: 7500
                        });
                        
                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.corretoraSelecionada = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao ativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
        },

        formatDateCustom(date, format) {
            return Formatacao.formatDateCustom(date, format);
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.primeiraLinha = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows; 
            this.carregarDados();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.ordenar = `${ sortField },${sortOrder}`;
            this.carregarDados();
        },

        visualizarHistorico(corretora) {
            this.historicoDialog = true;
            this.corretoraSelecionada = corretora;
        },

        ocultarHistorico() {
            this.historicoDialog = false;
            this.corretoraSelecionada = {};
        }
    }
}
</script>