<template>
    <div class="card">
        <div class="w-full align-items-center">            
            <h3>
                <i class="pi pi-building" style="font-size: 1.4rem"></i> 
                {{ codigoCliente ? 'Editar' : 'Cadastrar' }} corretora
            </h3>
            <hr />
        </div>

        <div class="grid p-fluid formgrid">
                
            <div class="col-12 sm:col-3 md:col-3 lg:col-3 xl:col-2">
                <div class="field">
                    <label>
                        <strong>ID</strong>
                    </label>
                    <InputText v-model="data.id" :disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-9 md:col-9 lg:col-9 xl:col-6">
                <div class="field">
                    <label>
                        <strong>
                            Cliente<span style="color:red;">&nbsp;*</span>
                        </strong>
                    </label>
                    <ProdutorFiltro
                        :disabled="false"
                        :campoObrigatorio="true"
                        :selecionado="data.cliente"
                        :paginacao="produtorConsulta"
                        :invalid="campoInvalido.cliente"
                        @pesquisar="pesquisarProdutor"
                        @selecionado="selecionarProdutor"
                    />
                </div>
            </div>
            
            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label>
                        <strong>CNPJ<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="data.cnpj" :disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-8 md:col-8 lg:col-8 xl:col-8">
                <div class="field">
                    <label>
                        <strong>Endereço<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="data.endereco" :disabled="false" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-4">
                <div class="field">
                    <label>
                        <strong>Bairro<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="data.bairro" :disabled="false" />
                </div>
            </div>

            <div class="col-12 sm:col-5 md:col-5 lg:col-5 xl:col-4">
                <div class="field">
                    <label>
                        <strong>Município<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="data.municipio" :disabled="false" />
                </div>
            </div>

            <div class="col-12 sm:col-1 md:col-1 lg:col-1 xl:col-1">
                <div class="field">
                    <label>
                        <strong>Estado<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="data.estado" :disabled="false" />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
                <div class="field">
                    <label>
                        <strong>CEP</strong>
                    </label>
                    <InputMask v-model="data.cep" :disabled="false" mask="99999-999"/>
                </div>
            </div>

            <div class="col-12 sm:col-8 md:col-8 lg:col-8 xl:col-5">
                <div class="field">
                    <label>
                        <strong>
                            E-mail<span style="color:red;">&nbsp;*</span>
                        </strong>
                    </label>
                    <InputText v-model="data.email" class="w-full" :class="{ 'p-invalid': campoInvalido.email }" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label>
                        <strong>
                            Contato<span style="color:red;">&nbsp;*</span>
                        </strong>
                    </label>
                    <InputText v-model="data.contato" class="w-full" :class="{ 'p-invalid': campoInvalido.contato }" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <div class="field">
                    <label>
                        <strong>
                            Telefone<span style="color:red;">&nbsp;*</span>
                        </strong>
                    </label>
                    <InputMask v-model="data.telefone" class="w-full" :class="{ 'p-invalid': campoInvalido.telefone }" mask="(99) 9999-9999"/>
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <div class="field">
                    <label>
                        <strong>
                            Celular<span style="color:red;">&nbsp;*</span>
                        </strong>
                    </label>
                    <InputMask v-model="data.celular" class="w-full" :class="{ 'p-invalid': campoInvalido.celular }" mask="(99) 9 9999-9999"/>
                </div>
            </div>
        </div>

        <DataTable
            showGridlines
            responsiveLayout="scroll"
            scrollDirection="both"
            :value="data.usuarios"
        >
            <template #header>
                <div class="flex align-items-center justify-content-between">
                    <strong style="font-size: 14px;">Usuários</strong>

                    <div class="flex">
                        <AutoComplete
                            class="mr-2"
                            v-model="usuarioSelecionado"
                            field="username"
                            :suggestions="usuarioFiltro"
                            :dropdown="true"
                            :forceSelection="true"
                            @complete="buscarUsuario"
                        />
                        <Button
                            icon="pi pi-plus"
                            label="Incluir"
                            class="p-button-outlined"
                            @click="incluirUsuario"
                        ></Button>
                    </div>
                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="username" header="Login"></Column>
            
            <Column header="Situação" style="width: 150px;">
                <template #body="{ data }">
                    {{ data.ativo ? "Ativo" : "Inativo" }}
                </template>
            </Column>

            <Column header="Opções"  style="width:4rem; text-align: center;">
                <template #body="{ data, index }">
                    <div class="flex-none">
                        <Button
                            title="Excluir"
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-danger flex-none"
                            @click="excluirUsuario(index)"
                        ></Button>
                    </div>
                </template>
            </Column>
        </DataTable>

        <div class="flex align-items-center mt-3">
            <Button label="Salvar" icon="pi pi-save" class="p-button p-component p-button-info mr-2" @click="salvar()" :disabled="doubleClick"></Button>
            <Button label="Cancelar" icon="pi pi-times" class="p-button p-component p-button-danger mr-2"  @click="voltar()"></Button>
        </div>
    </div>
</template>

<script>
import ProdutorFiltro from './ProdutorFiltro.vue';
import UsuarioService from '../../service/UsuarioService';
import ProdutorService from '../../service/ProdutorService';
import CorretoraService from '../../service/CorretoraService';
import StorageService from '../../service/StorageService';

export default {
    name: "CorretoraForm",
    components: { ProdutorFiltro },
    props: {
        codigoCliente: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            campoInvalido: {},
            data: {
                cliente: {}
            },

            produtoresFiltro: [],
            produtorConsulta: {},

            usuarioSelecionado: null,
            usuarioFiltro: []
        }
    },
    methods: {
        voltar() {
            this.$router.push(`/corretoras`);
        },

        pesquisarProdutor(filtros) {
            ProdutorService.pesquisarProdutor(filtros)
                .then(({ data }) => {
                    if(data) {
                        this.produtorConsulta = data;
                    }
                })
                .catch(error => {
                    this.produtorConsulta = {}
                    const toast = {
                        severity:'error', 
                        summary: 'Falha ao buscar produtor',
                        detail: 'Falha não mapeada',
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { data } = error.response;
                        toast.detail = data.message;
                    }

                    this.$toast.add(toast);
                });

        },

        selecionarProdutor(codProdutor) {
            if(codProdutor == undefined || codProdutor == null || codProdutor.trim() == "") {
                this.data.cliente = {};
                return;
            }

            ProdutorService.buscarPorCodigoProdutorInfoCorretora(codProdutor)
                .then(({ data }) => {
                    if(data) {
                        this.data["cliente"] = data;
                        this.data["endereco"] = data.endereco;
                        this.data["bairro"] = data.bairro;
                        this.data["municipio"] = data.municipio;
                        this.data["estado"] = data.estado;
                        this.data["cep"] = data.cep;
                        this.data["email"] = data.email;
                        this.data["telefone"] = data.telefone;

                        if(data.natureza === "PJ") {
                            this.data["cnpj"] = !data.cnpj ? "" : data.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
                        }
                        else {
                            this.data["cnpj"] = !data.cnpj ? "" : data.cnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
                        }
                    }
                })
                .catch(error => {
                    this.data.cliente = {};
                    const toast = {
                        severity:'error', 
                        summary: 'Falha ao buscar produtor',
                        detail: 'Falha não mapeada',
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { data } = error.response;
                        toast.detail = data.message;
                    }

                    this.$toast.add(toast);
                });
        },

        buscarUsuario(event){
            const filtro = !event.query.trim().length ? undefined : event.query;
            UsuarioService.buscarPorTipoUsuarioENomeOuUsername("CORRETORA", filtro)
                .then(({ data }) => {
                    if(!!data) {
                        this.usuarioFiltro = [...data];
                    }
                })
                .catch(error => {
                    this.usuarioFiltro = [];
                    const toast = {
                        severity:'error', 
                        summary: 'Falha ao buscar usuarios',
                        detail: 'Falha não mapeada',
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { data } = error.response;
                        toast.detail = data.message;
                    }

                    this.$toast.add(toast);
                });
        },

        incluirUsuario() {
            if(!this.usuarioSelecionado) {
                this.$toast.add({
                    severity:'warn',
                    summary: 'Atenção',
                    detail: 'Necessário selecionar um usuário.',
                    life: 7500
                });
                return;
            }

            if(!this.data.usuarios) {
                this.data.usuarios = [];
            }

            const usuario = this.data.usuarios.find(item => {
                return item.username === this.usuarioSelecionado.username;
            });

            if(!!usuario) {
                this.$toast.add({
                    severity:'warn',
                    summary: 'Atenção',
                    detail: `O usuário "${ this.usuarioSelecionado.username }" já foi adicionado.`,
                    life: 7500
                });
                return;
            }

            this.data.usuarios.push({
                nome: this.usuarioSelecionado.nome,
                username: this.usuarioSelecionado.username,
                ativo: true
            });

            this.usuarioSelecionado = null;
        },

        excluirUsuario(index) {
            this.data.usuarios.splice(index, 1);
        },

        montarJson() {
            const data = {};
            data["id"] = this.data.id;
            data["nome"] = this.data.cliente.nome;
            data["codigoCliente"] = this.data.cliente.codProdutor;
            data["cpfCnpj"] = this.data.cnpj;
            data["endereco"] = this.data.endereco;
            data["bairro"] = this.data.bairro;
            data["cidade"] = this.data.municipio;
            data["estado"] = this.data.estado;
            data["email"] = this.data.email;
            data["contato"] = this.data.contato;
            data["telefone"] = this.data.telefone;
            data["celular"] = this.data.celular;
            data["cep"] = this.data.cep;

            if(!!this.data.usuarios) {
                data["usuarios"] = this.data.usuarios.map(item => { 
                    const usuario = Object.assign({}, item);
                    usuario["login"] = usuario.username;
                    usuario["username"] = undefined;
                    return usuario;
                });
            }
            else {
                data["usuarios"] = [];
            }

            return data;
        },

        validarCampos() {
            let campos = [];

            if(!this.data.cliente.codProdutor || !this.data.cliente.nome) {
                campos.push("Cliente");
                this.campoInvalido.cliente = true;
            }
            else {
                this.campoInvalido.cliente = false;
            }

            if(!this.data.email) {
                campos.push("E-mail");
                this.campoInvalido.email = true;
            }
            else {
                this.campoInvalido.email = false;
            }

            if(!this.data.contato) {
                campos.push("Contato");
                this.campoInvalido.contato = true;
            }
            else {
                this.campoInvalido.contato = false;
            }

            if(!this.data.telefone) {
                campos.push("Telefone");
                this.campoInvalido.telefone = true;
            }
            else {
                this.campoInvalido.telefone = false;
            }

            if(!this.data.celular) {
                campos.push("Celular");
                this.campoInvalido.celular = true;
            }
            else {
                this.campoInvalido.celular = false;
            }

            if(!this.data.cnpj) {
                campos.push("CNPJ");
                this.campoInvalido.cnpj = true;
            }
            else {
                this.campoInvalido.cnpj = false;
            }

            if(!this.data.endereco) {
                campos.push("Endereço");
                this.campoInvalido.endereco = true;
            }
            else {
                this.campoInvalido.endereco = false;
            }

            if(!this.data.bairro) {
                campos.push("Bairro");
                this.campoInvalido.bairro = true;
            }
            else {
                this.campoInvalido.bairro = false;
            }

            if(!this.data.municipio) {
                campos.push("Município");
                this.campoInvalido.municipio = true;
            }
            else {
                this.campoInvalido.municipio = false;
            }

            if(!this.data.estado) {
                campos.push("Estado");
                this.campoInvalido.estado = true;
            }
            else {
                this.campoInvalido.estado = false;
            }

            if(campos.length > 0) {
                let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

                if(campos.length > 1) {
                    mensagem = "Por favor, preencher os campos: ";
                    mensagem += campos.join(", ");

                    const indice = mensagem.lastIndexOf(", ");
                    mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
                }

                this.$toast.add({
                    severity: 'warn',
                    summary: 'Não foi possível salvar a corretora.',
                    detail: mensagem, 
                    life: 15000
                });
            }

            return campos.length === 0;
        },

        salvar() {
            if(!this.validarCampos()) return;
            const dados = this.montarJson();
            CorretoraService.salvar(dados)
                .then(({ data }) => {
                    if(!!data) {
                        const detalhe = 'Corretora código ' + data.id +' foi salva com sucesso.';
                        StorageService.setMensagemToast("CORRETORA", 'success', 'Corretora salva', detalhe, true);
                        this.voltar();
                    }
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Não foi possível salvar a corretora.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["severity"] = 'warn';
                        toast["detail"] = message;

                    }

                    this.$toast.add(toast);
                });
        },

        preencherForm(data) {
            const dados = {};

            dados["id"] = data.id;
            dados["cliente"] = { nome: data.nome, codProdutor: data.codigoCliente };
            dados["cnpj"] = data.cpfCnpj;
            dados["endereco"] = data.endereco;
            dados["bairro"] = data.bairro;
            dados["municipio"] = data.cidade;
            dados["estado"] = data.estado;
            dados["email"] = data.email;
            dados["contato"] = data.contato;
            dados["telefone"] = data.telefone;
            dados["celular"] = data.celular;
            dados["cep"] = data.cep;

            dados["usuarios"] = data.usuarios.map(usuario => { 
                usuario["username"] = usuario.login;
                usuario["login"] = undefined;
                return usuario;
            });

            this.data = dados;
        }
    },

    mounted() {
        if(!!this.codigoCliente) {
            CorretoraService.buscarPorCodigoCliente(this.codigoCliente)
                .then(({ data }) => {
                    this.preencherForm(data);
                })
                .catch(error => {
                    this.dados = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar os dados do contrato.`,
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message, exception } = error.response.data;

                        if(!!exception) {
                            toast["detail"] = exception;
                        }
                    }

                    this.$toast.add(toast);
                });
        }
    }
}
</script>